<template>
  <material-card>
    <v-row class="mx-2">
      <v-col cols="12">
        <h5>Manual Sync Single SOS Receipt</h5>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="quickOrder.number"
          type="text"
          label="SOS Receipt Number / ID *"
        />
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="quickOrder.type"
          :items="['receipt_number','receipt_id']"
          label="Select Type *"
        />
      </v-col>
      <v-alert
        v-model="alert.active"
        dense
        text
        :type="alert.type"
      >
        {{ alert.text }}
      </v-alert>
      <v-col cols="12">
        <v-btn
          class="mt-2"
          block
          color="primary"
          @click="syncSOSOrder"
        >
          Sync SOS Order
        </v-btn>
      </v-col>
    </v-row>
  </material-card>
</template>

<script>
  import itemReceiptApi from '@/api/item-receipt'
  export default {
    name: 'SyncOrderSos',
    data () {
      return {
        quickOrder: {
          number: '',
          type: '',
        },
        alert: {
          active: false,
          type: 'info',
          text: '',
        },
      }
    },
    watch: {
      'alert.active': {
        handler: function (val, oldVal) {
          if (val === true) {
            setTimeout(() => {
              this.alert = Object.assign({}, { active: false, type: '', text: '' })
            }, 3000)
          }
        },
        deep: true,
      },
    },
    methods: {
      syncSOSOrder () {
        if (this.quickOrder.type === 'receipt_number') {
          this.quickOrder.sos_receipt_number = this.quickOrder.number
          this.quickOrder.sos_receipt_id = null
        } else {
          this.quickOrder.sos_receipt_id = this.quickOrder.number
          this.quickOrder.sos_receipt_number = null
        }
        itemReceiptApi.syncItemReceipt(this.quickOrder).then(response => {
          if (response.data.code !== 200) {
            this.alert = Object.assign({}, { active: true, type: 'error', text: response.data.message })
          } else {
            this.alert = Object.assign({}, { active: true, type: 'success', text: response.data.message })
          }
        }).catch(error => {
          console.log(error)
        })
      },
    },
  }
</script>

<style scoped>

</style>
