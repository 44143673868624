<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <breadcrumbs :items="breadcrumbsItems" />
    <v-row justify="center">
      <v-dialog
        v-model="addItemDialogs"
        persistent
        max-width="700px"
      >
        <v-card>
          <v-card-text>
            <v-form>
              <v-row class="my-2">
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-autocomplete
                    v-model="selectItem"
                    :items="items"
                    :search-input.sync="searchItem"
                    :disabled="isUpdating"
                    hide-no-data
                    hide-details
                    flat
                    label="Select Exist Item"
                    clearable
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="editedReceiptItem.label_show_quantity"
                    type="number"
                    label="Sent to Testing"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="editedReceiptItem.failed_in_receiving"
                    type="number"
                    label="Failed in Receiving"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="editedReceiptItem.fake_quantity"
                    type="number"
                    label="Fake"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="editedReceiptItem.item_rma"
                    type="number"
                    label="RMA"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    v-model="editedReceiptItem.item_description"
                    rows="1"
                    disabled
                    label="Description"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="9"
                >
                  <v-text-field
                    v-model="newItemPartNumber"
                    label="Or Create New No SOS Item"
                  />
                </v-col>
                <v-col
                  cols="3"
                >
                  <v-btn
                    class="mt-3"
                    text
                    @click="addNewItem()"
                  >
                    Create Item
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="editItemId <= 0"
              color="blue darken-1"
              text
              @click="addReceiptItem()"
            >
              Add
            </v-btn>
            <v-btn
              v-else
              text
              @click="updateReceiptItem()"
            >
              Update
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="resetItemEditInfo()"
            >
              Cancel
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <snack-bar ref="snackBar"></snack-bar>
      <v-col
        cols="12"
        md="9"
      >
        <material-card
          color="primary"
          icon="mdi-clipboard-edit-outline"
        >
          <template #title>
            Manual Order — <small class="text-body-1">{{ formTitle }} — Status: {{ receiptStatus }}</small>
          </template>
          <v-form>
            <v-row class="mx-2">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="editedReceiptInfo.po_number"
                  prepend-icon="mdi-all-inclusive"
                  label="* PO Number:"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="editedReceiptInfo.vendor"
                  prepend-icon="mdi-account-circle"
                  label="* Vendor:"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="editedReceiptInfo.item_receipt_date"
                  prepend-icon="mdi-calendar-blank-multiple"
                  label="* Receipt Date:"
                  type="date"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="editedReceiptInfo.net_days"
                  prepend-icon="mdi-clock-alert-outline"
                  type="number"
                  label="Net Order Days (No Net Order keep blank):"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="editedReceiptInfo.vendor_comment"
                  label="Comment: "
                  rows="2"
                  prepend-icon="mdi-comment-multiple-outline"
                />
              </v-col>
              <v-col
                cols="12"
                v-if="receiptId>0"
              >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="updateReceipt"
                  >
                    Update Receipt
                  </v-btn>
                </v-card-actions>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="receiptItemsTableHeader"
                  :items="receiptItems"
                  class="elevation-1"
                  dense
                >
                  <template v-slot:footer>
                    <v-spacer />
                    <v-btn
                      text
                      @click="addItemDialogs=true"
                    >
                      Add Item
                    </v-btn>
                    <v-divider
                      class="mx-1"
                      inset
                      vertical
                    />
                    <v-btn
                      text
                      color="red"
                    >
                      Reset Items
                    </v-btn>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editReceiptItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      v-if="item.id !== null && item.task_status.id === 1"
                      small
                      @click="deleteReceiptItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-card-actions v-if="receiptId < 1">
              <v-col cols="12">
                <v-btn
                  class="mr-5"
                  color="primary"
                  @click="saveReceipt()"
                >
                  Save Receipt
                </v-btn>
                <v-btn
                  color="red"
                  :disabled="disableResetReceipt"
                  @click="confirmResetReceiptDialogs = true"
                >
                  Reset Receipt
                </v-btn>
                <v-spacer />
              </v-col>
            </v-card-actions>
          </v-form>
        </material-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <sync-order-sos></sync-order-sos>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Breadcrumbs from '@/layouts/default/widgets/Breadcrumbs'
  import SnackBar from '@/components/app/SnackBar'
  import ItemApi from '@/api/item'
  import ReceiptApi from '@/api/item-receipt'
  import ReceiptItemApi from '@/api/item-receipt-item'
  import { singleItemReceipt } from '@/util/item-receipt-data-handle'
  import store from '@/store'
  import SyncOrderSos from '@/layouts/default/widgets/SyncOrderSos'
  export default {
    name: 'CreateEditManual',
    components: { SyncOrderSos, SnackBar, Breadcrumbs },
    data () {
      return {
        breadcrumbsItems: [
          {
            text: 'Item Receipt Table',
            disabled: false,
            to: { name: 'Item Receipt' },
          },
          {
            text: 'Manual Order',
            disabled: true,
            to: { name: 'Receipt/CreateEditManual' },
          },
        ],
        receiptStatus: 'Ready',
        receiptId: -1,
        defaultReceiptInfo: {
          id: null,
          po_number: '',
          vendor: '',
          body: '',
          item_receipt_date: '',
          net_days: 0,
          vendor_comment: '',
        },
        editedReceiptInfo: {
          id: null,
          po_number: '',
          vendor: '',
          body: '',
          item_receipt_date: '',
          net_days: 0,
          vendor_comment: '',
        },
        defaultReceiptItem: {
          id: null,
          item_receipt_id: null,
          item_id: null,
          item_part_number: '',
          sos_item_id: '',
          item_description: '',
          label_show_quantity: 0,
          failed_in_receiving: 0,
          fake_quantity: 0,
          item_rma: 0,
        },
        editedReceiptItem: {
          id: null,
          item_receipt_id: null,
          item_id: null,
          item_part_number: '',
          sos_item_id: '',
          item_description: '',
          label_show_quantity: 0,
          failed_in_receiving: 0,
          fake_quantity: 0,
          item_rma: 0,
        },
        receiptItemsTableHeader: [
          { text: '#', align: 'start', sortable: true, value: 'id' },
          { text: 'PN', sortable: false, value: 'item_part_number' },
          { text: 'Description', sortable: false, value: 'item_description' },
          { text: 'S to T', sortable: false, value: 'label_show_quantity' },
          { text: 'F in R', sortable: false, value: 'failed_in_receiving' },
          { text: 'Fake', sortable: false, value: 'fake_quantity' },
          { text: 'RMA', sortable: false, value: 'item_rma' },
          { text: 'Status', sortable: false, value: 'task_status.name' },
          { text: '', value: 'actions', sortable: false },
        ],
        receiptItems: [],
        // item Edit Function Related
        addItemDialogs: false,
        resetItemsDialogs: false,
        isUpdating: false,
        items: [],
        searchItem: '',
        selectItem: '',
        itemsPartNumber: [],
        itemsDbInfo: [],
        newItemPartNumber: '',
        editItemId: -1,
        // Receipt Function Related
        disableResetReceipt: false,
        confirmResetReceiptDialogs: false,
      }
    },
    computed: {
      formTitle () {
        return this.receiptId === -1 ? 'Create New Receipt' : 'Edit Receipt ' + this.editedReceiptInfo.po_number
      },
    },
    watch: {
      searchItem (val, oldVal) {
        if (val && val.length > 3) {
          ItemApi.quickSearch(val).then(response => {
            if (response.data.code === 200) {
              this.isUpdating = true
              this.itemsDbInfo = response.data.data
              response.data.data.forEach((item) => {
                this.itemsPartNumber.push(item.part_number)
              })
              this.isUpdating = false
              val && val !== this.select && this.itemsQuerySelections(val)
            }
          }).catch(error => {
            console.log(error)
          })
        }
      },
      selectItem (val) {
        if (val) {
          const result = this.itemsDbInfo.find(obj => obj.part_number === val)
          this.editedReceiptItem.item_part_number = result.part_number
          this.editedReceiptItem.item_description = result.description
          this.editedReceiptItem.item_id = result.id
          this.editedReceiptItem.sos_item_id = result.sos_item_id
        }
      },
      receiptId (val) {
        if (val >= 1) {
          this.loadReceipt()
        }
      },
    },
    created () {
      this.receiptId = this.$route.params.id
    },
    methods: {
      addReceiptItem () {
        if (this.receiptId > -1) {
          ReceiptItemApi.store(this.editedReceiptItem).then(response => {
            if (response.data.code !== 200) {
              store.set('message/storeMessage', {
                color: response.data.type,
                text: response.data.message,
                timeout: 3000,
              })
              this.$refs.snackBar.activeStatusInfo()
            }
          }).catch(error => {
            store.set('message/storeMessage', {
              color: 'error',
              text: error,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }).finally(() => {
            this.$nextTick(() => this.resetItemEditInfo())
            this.loadReceipt()
          })
        } else {
          this.receiptItems.push(this.editedReceiptItem)
          this.resetItemEditInfo()
        }
      },
      resetItemEditInfo () {
        this.addItemDialogs = false
        // Edit Receipt Item Info
        this.editedReceiptItem = Object.assign({}, this.defaultReceiptItem)
        this.editItemId = -1
        // Auto Search
        this.items.length = 0
        this.itemsPartNumber.length = 0
        this.isUpdating = false
        this.searchItem = ''
        this.selectItem = ''
        this.itemsDbInfo.length = 0
        this.newItemPartNumber = ''
      },
      addNewItem () {
        ItemApi.store({
          part_number: this.newItemPartNumber,
          description: null,
          item_type_id: null,
          bin_number: null,
          specify_keyword: null,
        }).then(response => {
          if (response.data.code === 200) {
            this.itemsDbInfo.push(response.data.data)
            this.searchItem = response.data.data.part_number
            this.selectItem = response.data.data.part_number
          }
        })
      },
      updateReceiptItem () {
        const editItem = this.receiptItems.find(obj => obj.id === this.editItemId)
        const index = this.receiptItems.indexOf(editItem)
        if (this.editedReceiptItem.id == null) {
          Object.assign(this.receiptItems[index], this.editedReceiptItem)
        } else {
          ReceiptItemApi.update(this.editedReceiptItem).then(response => {
            if (response.data.code !== 200) {
              store.set('message/storeMessage', {
                color: response.data.type,
                text: response.data.message,
                timeout: 3000,
              })
            }
          }).catch(error => {
            store.set('message/storeMessage', {
              color: 'error',
              text: error,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          })
        }
        this.resetItemEditInfo()
        this.loadReceipt()
      },
      itemsQuerySelections (val) {
        setTimeout(() => {
          this.items = this.itemsPartNumber.filter(e => {
            return (e || '').toLowerCase().indexOf((val || '').toLowerCase()) > -1
          })
        }, 300)
      },
      editReceiptItem (item) {
        this.editedReceiptItem = Object.assign({}, item)
        ItemApi.show(item.item_id).then(response => {
          if (response.data.code === 200) {
            this.itemsDbInfo.push(response.data.data)
            this.editItemId = item.id
            this.editedReceiptItem.item_part_number = response.data.data.part_number
            this.editedReceiptItem.item_description = response.data.data.description
            this.searchItem = response.data.data.part_number
            this.selectItem = response.data.data.part_number
            this.addItemDialogs = true
          }
        })
      },
      deleteReceiptItem (item) {
        if (item.id) {
          ReceiptItemApi.delete(item.id).then(response => {
            if (response.data.code === 200) {
              this.receiptItems.splice(this.receiptItems.indexOf(item), 1)
            } else {
              store.set('message/storeMessage', {
                color: response.data.type,
                text: response.data.message,
                timeout: 3000,
              })
              this.$refs.snackBar.activeStatusInfo()
            }
          }).catch(error => {
            store.set('message/storeMessage', {
              color: 'error',
              text: error,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          })
          this.loadReceipt()
        } else {
          this.receiptItems.splice(this.receiptItems.indexOf(item), 1)
        }
      },
      resetReceiptInfo () {
        this.editedReceiptInfo = Object.assign({}, this.defaultReceiptInfo)
      },
      loadReceipt () {
        this.receiptStatus = 'Loading ...'
        ReceiptApi.show(this.receiptId).then(response => {
          if (response.status !== 200) this.$router.push({ name: 'Error401' }).catch(() => {})
          if (response.data.data.created_by !== 'manual') this.$router.push({ name: 'Item Receipt' }).catch(() => {})
          this.editedReceiptInfo = ({}, singleItemReceipt(response.data.data))
          this.defaultReceiptItem.item_receipt_id = response.data.data.id
          this.editedReceiptItem.item_receipt_id = response.data.data.id
          this.receiptItems = response.data.data.items
          this.receiptStatus = 'Ready'
        }).catch(() => {
          this.$router.push({ name: 'Error401' }).catch(() => {})
        })
      },
      saveReceipt () {
        ReceiptApi.storeManualOrder(this.editedReceiptInfo, this.receiptItems).then(response => {
          this.resetItemEditInfo()
          this.resetReceiptInfo()
        }).catch(error => {
          console.log(error)
        })
      },
      updateReceipt () {
        this.receiptStatus = 'Updating ...'
        ReceiptApi.update(this.editedReceiptInfo).then(response => {
          if (response.data.code !== 200) {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
          this.loadReceipt()
        }).catch(() => {
          this.$router.push({ name: 'Error401' }).catch(() => {})
        })
      },
    },
  }
</script>

<style scoped>

</style>
